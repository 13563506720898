import React from 'react';
import { about, aboutSosial, biology, listIcon, mission, service1, service9, services9 } from '../../../assets/index';

const Advertising = () => {
  return (
    <>
     <div className='pt-[79px]'>
<div
            className="relative h-[203px] md:h-[339px]"
            style={{
              backgroundImage: `url(${services9})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            {/* Metin kutusu */}
            <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-end">
              <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold relative bottom-10">
              Reklam xidməti
              </div>
            </div>
          </div>


      <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px] text-[18px] ">
              <div>
              <p className='text-[#FBFBFB] font-medium'>Bu xidmətimiz bizneslərin məhsul və xidmətlərini hədəf auditoriyasına çatdırmaq və tanıtmaq üçün peşəkar reklam fəaliyyətini əhatə edir. Bu zaman şirkətinizə uyğun müxtəlif kanal və üsullardan istifadə olunur.</p>
              <div className='flex mt-8'>
                <img className='h-8 w-8' src={service9}></img>
                <p className='text-[#FBFBFB] ml-3'>Reklam xidmətinə daxildir:</p>
              </div>
              <ul className='text-[#FBFBFB] mt-[28px] text-[18px] md:w-[1220px]'>
                <div className='flex'>
                  <div className='flex mt-2 mr-2'>
                  <img className='w-3 h-3' src={listIcon}></img>
                  </div>
                  <p className='flex-1'> Ənənəvi reklam - TV, radio, qəzet, jurnal;</p>
                </div> 
                <div className='flex'>
                  <div className='flex mt-2 mr-2'> 
                  <img className='w-3 h-3' src={listIcon}></img>
                  </div>
                  <p className='flex-1'>Çap reklamı - poster, banner, flayer, broşür, kataloq;</p>
                </div>
                <div className='flex'>
                  <div className='flex mt-2 mr-2'>
                  <img className='w-3 h-3' src={listIcon}></img>
                  </div>
                  <p className='flex-1'>İctimai məkanlarda reklamlar;</p>
                </div>
                <div className='flex'>
                  <div className='flex mt-2 mr-2'>
                  <img className='w-3 h-3' src={listIcon}></img>
                  </div>
                  <p className='flex-1'>Çöl reklamları.</p>
                </div>
      
      </ul>
      
              </div>
            </div>
     </div>
    </>
  );
};

export default Advertising;