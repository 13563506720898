import React from 'react';
import { about, aboutSosial, biology, listIcon, mission, service1, service10, services10 } from '../../../assets/index';

const Design = () => {
  return (
    <>
     <div className='pt-[79px]'>
<div
            className="relative h-[203px] md:h-[339px]"
            style={{
              backgroundImage: `url(${services10})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            {/* Metin kutusu */}
            <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-end">
              <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold relative bottom-10">
              Qrafik dizayn
              </div>
            </div>
          </div>


       <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px] text-[18px] ">
                 <div>
                 <p className='text-[#FBFBFB] font-medium'>Bu xidmətimiz bizneslərin məhsul və xidmətlərini hədəf auditoriyasına çatdırmaq və tanıtmaq üçün peşəkar reklam fəaliyyətini əhatə edir. Bu zaman biz şirkətinizə uyğun müxtəlif kanal və üsullardan istifadə edirik.</p>
                 <div className='flex items-center mt-8'>
                   <img className='h-8 w-8' src={service10}></img>
                   <p className='text-[#FBFBFB] ml-3'>Qrafik dizayn xidmətinə daxildir:</p>
                 </div>
                 <ul className='text-[#FBFBFB] mt-[28px] text-[18px] md:w-[1220px]'>
                   <div className='flex'>
                     <div className='flex mt-2 mr-2'>
                     <img className='w-3 h-3' src={listIcon}></img>
                     </div>
                     <p className='flex-1'>Loqo və Brendinq Dizaynı (şirkət, brend və ya layihəniz üçün unikal loqo dizayn, brend rəng palitrası və korporativ kimliyin yaradılması, vizit kartlar, brend kitabçalar, brend dəftərxana materiallarının dizaynı);</p>
                   </div>
                   <div className='flex'>
                     <div className='flex mt-2 mr-2'>
                     <img className='w-3 h-3' src={listIcon}></img>
                     </div>
                     <p className='flex-1'>Sosial Media Dizaynı (Facebook, Instagram, TikTok və LinkedIn üçün peşəkar post və “story” dizaynları, reklam bannerləri və qrafiklər, hədəf auditoriyanı cəlb edən kreativ kontentlər);</p>
                   </div>
                   <div className='flex'>
                     <div className='flex mt-2 mr-2'>
                     <img className='w-3 h-3' src={listIcon}></img>
                     </div>
                     <p className='flex-1'>Çap Materiallarının Dizaynı (broşür, flayer, buklet və poster dizaynı, qəzet və jurnal reklamları üçün xüsusi dizayn, məhsul qablaşdırması və etiket dizaynları);</p>
                   </div>
                   <div className='flex'>
                     <div className='flex mt-2 mr-2'>
                     <img className='w-3 h-3' src={listIcon}></img>
                     </div>
                     <p className='flex-1'>Vebsayt və UI/UX Dizaynı (mobil tətbiq və vebsaytlar üçün interfeyslər, ikonlar, düymələr və digər vizual elementlərin yaradılması);</p>
                   </div>
                   <div className='flex'>
                     <div className='flex mt-2 mr-2'>
                     <img className='w-3 h-3' src={listIcon}></img>
                     </div>
                     <p className='flex-1'>Animasiya və motion qrafik (reklam çarxları üçün animasiya və dinamik dizaynlar, sosial media üçün qısa və cəlbedici animasiyalar);</p>
                   </div>
                   <div className='flex'>
                     <div className='flex mt-2 mr-2'>
                     <img className='w-3 h-3' src={listIcon}></img>
                     </div>
                     <p className='flex-1'>Reklam Bannerləri və Outdoor Dizayn (billboard, LED ekran və afişa dizaynları, çöl reklamları üçün kreativ həllər).</p>
                   </div>
         
         </ul>
         
                 </div>
               </div>
     </div>
    </>
  );
};

export default Design;