import React from 'react';
import { about, aboutSosial, biology, mission, service1 } from '../../assets/index';

const Partners = () => {
  return (
    <>
     <div className='pt-[79px]'>
   <div
                                              className="relative h-[203px] md:h-[339px]"
                                              style={{
                                                backgroundImage: `url(${aboutSosial})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                              }}
                                            >
                                              {/* Metin kutusu */}
                                              <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-center">
                                                <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold ">
                                                Tərəfdaşlar
                                                </div>
                                              </div>
                                            </div>

     </div>
    </>
  );
};

export default Partners;