import React, { useEffect, useState } from "react";
import PaginatedItems from './PaginatedItems';
import axios from "axios";
import { careerJoin, searchProduct, vakansiyalar, values1 } from "../../../assets";
import { useTranslation } from "react-i18next";


const CareerJobs = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [jobFilters, setJobFilters] = useState({});
  const [categoryFilters, setCategoryFilters] = useState({});
  const [jobSearch, setJobSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true)
  const { t, i18n } = useTranslation();


  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `https://www.gpr.az/gpr/jobs.php`,
        { withCredentials: false }
      );
  

      setProducts(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleJobChange = (job) => {
    setJobFilters((prev) => ({
      ...prev,
      [job]: !prev[job],
    }));
  };

  const handleCategoryChange = (category) => {
    setCategoryFilters((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const clearJobFilters = () => {
    setJobFilters({});
    setJobSearch("");
  };

  // Filtering logic
  const filteredProducts = products.filter(product => {
    const matchesSearch = product.job.toLowerCase().includes(searchQuery.toLowerCase());

    // Check if brand filters are applied
    const hasSelectedJob = Object.keys(jobFilters).some(key => jobFilters[key]);
    const matchesJob = !hasSelectedJob || jobFilters[product.job];

    // Check if gender filters are applied
    const hasSelectedCategory = Object.keys(categoryFilters).some(key => categoryFilters[key]);
    const matchesCategory = !hasSelectedCategory || categoryFilters[product.category];

    return matchesSearch && matchesJob && matchesCategory;
  });


  // Brands to display (for example purposes)
  const allJob = [
    "Mühasib", "HR", "PR Menecer",  "Mühasib", "HR", "PR Menecer",  "Mühasib", "HR", "PR Menecer", 
  ];

  // Filter brands based on search input
  const filteredJob = allJob.filter(job =>
    job.toLowerCase().includes(jobSearch.toLowerCase())
  );


  return (
    <>
      <div className='bg-[black] pt-[79px]'>
      <div
                className="relative h-[203px] md:h-[339px]"
                style={{
                  backgroundImage: `url(${vakansiyalar})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                {/* Metin kutusu */}
                <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-center">
                  <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold ">
                  Mövcud vakansiyalar
                  </div>
                </div>
              </div>
        <div className="max-w-containerSm md:max-w-container mx-auto mt-10 flex">
          <div className="flex items-center justify-center bg-[#1C1C1C] rounded-l-2xl pl-4 w-16">
            <img className="w-6 h-6" src={searchProduct}></img>
          </div>
        <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange} 
              className="p-2 w-full bg-[#1C1C1C] text-[white] md:inline-block focus:outline-none"
            />
             <div className="flex items-center"> 
             <button className='text-[white] bg-primeColor text-[18px] font-medium  rounded-r-[20px] w-[99px] md:w-[148px] h-[44px] md:h-[60px] flex items-center justify-center'>
            {t("Axtar")}
            </button> 
             </div>
        </div>
        
        <div className='md:flex max-w-containerSm md:max-w-container mx-auto mt-5 md:mt-20 justify-between'>
          {/* Left Filter Section */}
          <div className="md:w-[265px] hidden md:block bg-[#1C1C1C] h-[506px] rounded-2xl p-5 ">

            <div className="mt-4">
  <label className="text-white font-medium mb-7">Kateqoriya:</label>
  <div className="flex flex-col">
    {["experienced", "youth"].map((category) => {
      const labelMap = {
        experienced: "Təcrübəli kadr",
        youth: "Təcrübəçi",
      };

      return (
        <div key={category}>
          <input
            type="checkbox"
            id={category}
            checked={!!categoryFilters[category]}
            onChange={() => handleCategoryChange(category)}
            className="accent-[#dfb66f]" 
          />
          <label htmlFor={category} className="ml-2 text-white">
            {labelMap[category]}
          </label>
        </div>
      );
    })}
  </div>
</div>

            <div className="w-[211.01px] h-[0px] border border-[#3e3e3e] mt-4"></div>
            
            <div className="mt-4">
             <div className="flex justify-between">
             <label className='text-white'>Vəzifə:</label>
              <button
                  onClick={clearJobFilters}
                  className="text-[#5b5b5b] rounded"
                >
                  Təmizlə
                </button>
             </div>
              <div className="flex items-center mt-1">
                <input
                  type="text"
                  value={jobSearch}
                  onChange={(e) => setJobSearch(e.target.value)}
                  placeholder="Axtar..."
                  className="mt-4 p-2 rounded-2xl w-[210px] bg-[#1C1C1C] border-[#595959] border-2 text-[white] mb-4"
                />
              </div>
              <div className="flex flex-col overflow-y-scroll mt-2 max-h-48">
                {filteredJob.map((job) => ( // Show only the first 3 brands
                  <div key={job}> 
                    <input
                      type="checkbox"
                      id={job}
                      checked={!!jobFilters[job]}
                      onChange={() => handleJobChange(job)}
                      className="accent-[#dfb66f]" 
                    />
                    <label htmlFor={job} className="ml-2 text-white">{job.charAt(0).toUpperCase() + job.slice(1)}</label>
                  </div>
                ))}
              </div>
            </div>
            

            
          </div>

          {/* Products Section */}
          <div className="md:w-[748px]">
            <div className="flex justify-between mb-4">
            
            {/* Sort Options */}
          
            <div className="flex md:hidden justify-between mt-4">
           
            <div
  onClick={toggleHamburgerMenu}
  className="mt-[2px] p-2 rounded-2xl bg-[#020202] text-[white] border-[#595959] border-2 w-[150px] ml-2"
>
  Filterlə
</div>
<button className="md:hidden focus:outline-none">
  {isHamburgerMenuOpen && (
    <div
      className={`fixed inset-y-0 bg-[black] left-0 transform ${
        isHamburgerMenuOpen ? "translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 ease-in-out w-full z-50 shadow-lg`}
    >
      <div className="flex">
        <button
          className="absolute top-5 right-5 p-2 rounded" 
          onClick={toggleHamburgerMenu}
        >
          <i className="fa-solid fa-xmark text-[32px] text-[white]"></i>
        </button>
      </div>
      <div className="flex flex-col items-start p-8 w-full">
        <div className="space-y-2 sm:space-y-0 h-screen">
          <div>
            <h4 className="text-white text-xl font-medium text-left">
              Filtrlər:
            </h4>

            <div className="mt-4 text-left">
              <label className="text-white font-medium mb-7">
                Kateqoriya:
              </label>
              <div className="flex flex-col items-start"> {/* `items-start` added */}
                {["experienced", "youth"].map((category) => {
                  const labelMap = {
                    experienced: "Təcrübəli kadr",
                    youth: "Təcrübəçi",
                  };

                  return (
                    <div key={category} className="text-left"> {/* Added `text-left` */}
                      <input
                        type="checkbox"
                        id={category}
                        checked={!!categoryFilters[category]}
                        onChange={() => handleCategoryChange(category)}
                        className="accent-[#dfb66f]" 
                      />
                      <label htmlFor={category} className="ml-2 text-white">
                        {labelMap[category]}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="w-[211.01px] h-[0px] border border-[#3e3e3e] mt-4"></div>

            <div className="mt-4 text-left">
              <div className="flex justify-between">
                <label className="text-white">Vəzifə:</label>
                <button
                  onClick={clearJobFilters}
                  className="text-[#5b5b5b] rounded"
                >
                  Təmizlə
                </button>
              </div>
              <div className="flex items-center mt-1">
                <input
                  type="text"
                  value={jobSearch}
                  onChange={(e) => setJobSearch(e.target.value)}
                  placeholder="Axtar..."
                  className="mt-4 p-2 rounded-2xl w-[210px] bg-[#020202] border-[#595959] border-2 text-[white] mb-4"
                />
              </div>
              <div className="flex flex-col overflow-y-scroll mt-2 max-h-48 items-start"> {/* `items-start` added */}
                {filteredJob.map((job) => (
                  <div key={job}>
                    <input
                      type="checkbox"
                      id={job}
                      checked={!!jobFilters[job]}
                      onChange={() => handleJobChange(job)}
                      className="accent-[#dfb66f]" 
                    />
                    <label
                      htmlFor={job}
                      className="ml-2 text-white text-left"
                    >
                      {job.charAt(0).toUpperCase() + job.slice(1)}
                    </label>
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )}
</button>

            </div>
            </div> 
            {isLoading ?  <p className="text-[white]"> Loading...</p> :
              <PaginatedItems itemsPerPage={120} items={filteredProducts} />  
            }
           
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerJobs;


















































