import React from 'react';
import { project1, project2, project3 } from '../../assets/index';

const News = () => {
  const projects = [
    { id: 1, image: project1, title: "Fusce tincidunt commodo sapien, qu" },
    { id: 2, image: project2, title: "Fusce tincidunt commodo sapien, qu" },
    { id: 3, image: project3, title: "Fusce tincidunt commodo sapien, qu" },
    { id: 4, image: project1, title: "Fusce tincidunt commodo sapien, qu" },
    { id: 5, image: project2, title: "Fusce tincidunt commodo sapien, qu" },
    { id: 6, image: project3, title: "Fusce tincidunt commodo sapien, qu" },
  ];

  return (
    <>
      <div className="pt-[79px]">
        <div className="mx-auto mt-[20px] md:mt-[52px] relative">
          <div className="flex justify-center items-center mx-auto">
            <div className="w-[2px] h-[20px] bg-[#E0B497] mb-4 mt-4"></div>
            <p className="text-[16px] font-medium text-center text-[#E0B497] ml-[5px]">
              Xəbərlər
            </p>
          </div>

          <p className="text-[28px] md:text-[40px] font-bold text-center text-gradient">
            Xəbərlərlə tanış olun
          </p>

          <div className="max-w-containerSm md:max-w-container mx-auto mt-[44px] md:mt-[60px]">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {projects.map((project) => (
                <a key={project.id} href={`/news/${project.id}`} className="relative group">
                  <div className="relative md:w-[400px]">
                    <img
                      className="rounded-[20px] mb-4 w-full"
                      src={project.image}
                      alt={`Project ${project.id}`}
                    />
                    <div className="absolute inset-0 rounded-[20px] flex items-end">
                      <p className="text-white text-lg font-semibold p-4">{project.title}</p>
                    </div>
                  </div>
                </a>
              ))}
            </div>

            <div className="mt-5 mx-auto flex justify-center">
              <a href="/projects">
                <p className="text-black text-base font-normal inline-block border-b border-black">
                  Hamısını gör
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default News;