import React, { useState } from 'react';
import { about, aboutSosial, biology, careerJoin, mission, service1 } from '../../../assets/index';
import { useTranslation } from 'react-i18next';




const faqs = [
  {
    question: "Şirkətin əsas missiyası nədən ibarətdir?",
    answer: "Şirkətin missiyası brendlərin inkişafına təkan vermək, şirkətləri tanıtmaq, davamlı əlaqələr quran müstəsna kommunikasiya strategiyaları təqdim etməklə brendləri və şirkətləri yüksəltməkdir. Doğru strategiya, zövqlü yaradıcılıq və dəqiq icraya əsaslanaraq istər brendin təqdimatı, istər şirkət reputasiyasının idarə edilməsi, istərsə də hərtərəfli PR kampaniyası ilə, bizim xüsusi həllərimiz müştərilərimizin mesajlarının lazımi anda doğru auditoriyaya çatdırılmasını təmin edir.",
  },
  {
    question: "Şirkətin əsas missiyası nədən ibarətdir?",
    answer: "Şirkətin missiyası brendlərin inkişafına təkan vermək, şirkətləri tanıtmaq, davamlı əlaqələr quran müstəsna kommunikasiya strategiyaları təqdim etməklə brendləri və şirkətləri yüksəltməkdir. Doğru strategiya, zövqlü yaradıcılıq və dəqiq icraya əsaslanaraq istər brendin təqdimatı, istər şirkət reputasiyasının idarə edilməsi, istərsə də hərtərəfli PR kampaniyası ilə, bizim xüsusi həllərimiz müştərilərimizin mesajlarının lazımi anda doğru auditoriyaya çatdırılmasını təmin edir.",
  },
  {
    question: "Şirkətin əsas missiyası nədən ibarətdir?",
    answer: "Şirkətin missiyası brendlərin inkişafına təkan vermək, şirkətləri tanıtmaq, davamlı əlaqələr quran müstəsna kommunikasiya strategiyaları təqdim etməklə brendləri və şirkətləri yüksəltməkdir. Doğru strategiya, zövqlü yaradıcılıq və dəqiq icraya əsaslanaraq istər brendin təqdimatı, istər şirkət reputasiyasının idarə edilməsi, istərsə də hərtərəfli PR kampaniyası ilə, bizim xüsusi həllərimiz müştərilərimizin mesajlarının lazımi anda doğru auditoriyaya çatdırılmasını təmin edir.",
  },
  {
    question: "Şirkətin əsas missiyası nədən ibarətdir?",
    answer: "Şirkətin missiyası brendlərin inkişafına təkan vermək, şirkətləri tanıtmaq, davamlı əlaqələr quran müstəsna kommunikasiya strategiyaları təqdim etməklə brendləri və şirkətləri yüksəltməkdir. Doğru strategiya, zövqlü yaradıcılıq və dəqiq icraya əsaslanaraq istər brendin təqdimatı, istər şirkət reputasiyasının idarə edilməsi, istərsə də hərtərəfli PR kampaniyası ilə, bizim xüsusi həllərimiz müştərilərimizin mesajlarının lazımi anda doğru auditoriyaya çatdırılmasını təmin edir.",
  },
];

const CareerJoin = () => {


  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
     <div className='pt-[79px]'>
    <div
                className="relative h-[203px] md:h-[339px]"
                style={{
                  backgroundImage: `url(${careerJoin})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                {/* Metin kutusu */}
                <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-center">
                  <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold ">
                  Karyera hekayəniz GPR-la başlasın!
                  </div>
                </div>
              </div>


     <div className='bg-[black] md:py-[86px] py-[32px]'>
     <div className="max-w-containerSm md:max-w-container mx-auto">
        <div className='max-w-[966px]'>
          <p className='text-[#FBFBFB]'><span className='font-semibold'>Dəyərli namizəd,</span> karyera hekayənizin əsas qəhrəmanı sizsiniz. Biz isə bu hekayənin parlaq olması üçün sizə ilham verən və dəstək göstərən tərəfdaş olmaqdan qürur duyuruq. <br></br><br></br>

Hər bir uğurun başlanğıc nöqtəsi var. Sizin karyera hekayənizin də ilk səhifəsini bizimlə yazmağa dəvət edirik. Hər bir addımınızda dəstək göstərən, öyrənmək və inkişaf etmək üçün geniş imkanlar təqdim edən bir iş mühiti arzulayırsınızsa, doğru ünvandasınız! <br></br><br></br>



GPR <span className="text-gradient">peşəkar inkişaf</span> təklif edir. Hər bir əməkdaşımızın inkişafı şirkətimizin uğurunun əsasını təşkil edir. Bizimlə təlimlər, sertifikatlaşdırma proqramları və mentorluq imkanları sayəsində bilik və bacarıqlarınızı daim artırmaq şansı əldə edəcəksiniz. <br></br><br></br>

GPR-da  <span className="text-gradient">dinamik iş mühiti</span> mövcuddur. Komandamızda hər bir əməkdaşın fikrinə dəyər verilir. Bizimlə yeni ideyalarınız və yaradıcılığınızla böyük layihələrə töhfə vermək imkanınız olacaq. <br></br><br></br>

GPR  <span className="text-gradient">karyera yüksəlişinə</span> xüsusi önəm verir. Biz sadəcə iş təklif etmirik, həm də uzunmüddətli karyera perspektivləri yaradırıq. Performansınıza əsasən, irəliləmək və rəhbər vəzifələrə yüksəlmək üçün şərait təmin edirik. <br></br><br></br>

GPR-da  <span className="text-gradient">dəyərlər və etibarlılıq</span> prioritetdir. Ədalətlilik, komanda ruhu və loyallıq bizim əsas prinsiplərimizdir. Əməkdaşlarımıza qarşı dürüst yanaşmamız və dəyərlərimiz hər zaman öncəliyimizdir.</p>
        </div>
      </div>
     </div>
     <div className='bg-[#1C1C1C]  py-[40px] md:py-[60px]'>
     <div className="max-w-containerSm md:max-w-container mx-auto">
        <div className='max-w-[1036px]'>
          <p className='text-[#FBFBFB] font-semibold text-gradient'>Peşəkarlar üçün</p>
        <p className='text-[#FBFBFB] text-[24px] md:text-[40px] font-bold'>Gəlin, birlikdə <span className='text-gradient'>gələcək karyeranızı</span> formalaşdıraq!</p>
        <p className='mt-5 md:mt-8 text-[#FBFBFB] '>Karyeranızı inkişaf etdirmək üçün düzgün tərəfdaş seçmək vacibdir. GPR karyeranızla bağlı xəyallarınızı gerçəkləşdirmək üçün doğru ünvandır!</p>
        <a href='/career/jobs'>
            <button className='text-[white] bg-primeColor rounded-[20px] w-[148px] h-[40px] flex items-center justify-center mt-6'>
            Daha ətraflı
            </button>
          </a>
        </div>
      </div>
     </div>
     <div className='bg-[black] md:py-[86px] py-[32px]'>
     <div className="max-w-containerSm md:max-w-container mx-auto ">
        <div className='max-w-[1035px]'>
          <p className='text-[#FBFBFB] text-[24px] font-bold md:text-[40px]'>Bizimlə işləmək nə üçün doğru seçimdir?</p>
        <p className='text-[#FBFBFB] md:mt-[32px] mt-[20px]'>GPR təkcə iş yeri deyil, komanda üzvlərini uğura aparan, şəxsi və peşəkar inkişaflarını dəstəkləyən professionalların toplandığı yaradıcı mühitdir. Komandamızla birgə çalışmağa qərar verdiyiniz təqdirdə, siz karyera yolunuzda sonsuz uğurlar əldə edəcəksiniz. PR və marketinq sahəsində GPR-da qazandığınız təcrübə fəaliyyətinizdə sizlər üçün çox yararlı olacaq. Uğura doğru GPR-la birlikdə addımlayın!</p>
        </div>
      </div>
     </div>
     <div className='bg-[#1C1C1C]  py-[40px] md:py-[60px]'>
     <div className="max-w-containerSm md:max-w-container mx-auto">
        <div className='max-w-[1036px]'>
          <p className='text-[#FBFBFB] font-semibold text-gradient'>Təcrübəçilər üçün</p>
        <p className='text-[#FBFBFB] text-[24px] md:text-[40px] font-bold'> <span className='text-gradient'>Yaxşı başlanğıc</span> uğurlu yolun yarısıdır!</p>
        <p className='mt-5 md:mt-8 text-[#FBFBFB] '>Dəyərli Namizəd, əgər özünüzü bizimlə birlikdə inkişaf edən gələcəyin bir hissəsi kimi görürsünüzsə, bu gün müraciət edin!
Seçiminiz nə olursa olsun, sizlərə gələcək karyeranızda uğurlar arzulayırıq!
İnanırıq ki, GPR-ın təqdim etdiyi sahələr üzrə yolumuz yenidən kəsişəcəkdir!</p>
        <a href='/career/join/youths'>
            <button className='text-[white] bg-primeColor rounded-[20px] w-[148px] h-[40px] flex items-center justify-center mt-6'>
            Daha ətraflı
            </button>
          </a>
        </div>
      </div>
     </div>
     <div className="max-w-containerSm md:max-w-container mt-[64px] md:mt-[86px] mx-auto">
          <p className="text-white text-[24px] md:text-[40px] font-bold">{t("Tez-tez soruşulan suallar")}</p>
        <div className="space-y-8 mt-[40px] md:mt-[60px]">
    {faqs.map((faq, index) => (
      <div 
        key={index}
        className={`rounded-[18px] overflow-hidden px-4 md:px-10 py-3 bg-white shadow ${
          activeIndex === index ? ' bg-primeColor' : ''
        }`}
      >
        <div
          onClick={() => toggleFAQ(index)}
          className="flex justify-between items-center cursor-pointer group"
        >
          <h2
            className={`text-[14px] md:text-[20px] font-bold ${
              activeIndex === index ? 'text-white' : 'text-[black]'
            }`}
          >
            {t(faq.question)}
          </h2>
          <div
            className={`w-[32px] h-[32px] rounded-[50%] ${
              activeIndex === index ? '' : 'bg-[white]'
            } flex justify-center items-center shadow border border-white`}
          >
            <i
              className={`${
                activeIndex === index
                  ? 'fa-solid fa-arrow-up text-[white]'
                  : 'fa-solid fa-arrow-down text-[black]'
              }`}
            />
          </div>
        </div>
        {activeIndex === index && (
          <div className="pt-4">
            <p className="text-[#FBFBFB] text-[14px] md:text-[16px] md:w-[1100px]">{t(faq.answer)}</p>
          </div>
        )}
      </div>
    ))}
  </div>
    </div>
     </div>
    </>
  );
};

export default CareerJoin;

