import React from 'react';
import { about, aboutSosial, biology, mission, service1 } from '../../../assets/index';

const Biology = () => {
  return (
    <>
     <div className='pt-[79px]'>
     <div
  className="relative h-[203px] md:h-[339px]"
  style={{
    backgroundImage: `url(${biology})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }}
>
  {/* Metin kutusu */}
  <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-center">
    <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold">
    Biology
    </div>
  </div>
</div>


      <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px] ">
        <div className='md:w-[929px]'>
        <p className='text-[#FBFBFB] font-medium text-[18px] md:text-[24px]'>A world where all cats live happily and healthily in their homes</p>
        <p className='text-[#FBFBFB] mt-[28px] text-[16px] md:text-[20px]'>Sometimes we are indifferent to what is happening around us, and unfortunately, it is not a secret that humans make more harm to the environment than animals.</p>
        <p className='text-[#FBFBFB] mt-[40px] font-medium text-[18px] md:text-[24px]'>Make sure a kitten is not hungry and dehydrated</p>
        <p className='text-[#FBFBFB] mt-[28px] text-[16px] md:text-[20px]'>Place extra water in kitten food until they learn how to lap up liquids before training them to drink water. Milk formula, soft kitten food, and soft bones for extra calcium.</p>
        <p className='text-[#FBFBFB] mt-[40px] font-medium text-[18px] md:text-[24px]'>Train kittens to use their house</p>
        <p className='text-[#FBFBFB] mt-[28px] text-[16px] md:text-[20px]'>Bring kitten to the shelter house and feed it there. Socialize kittens with humans. Make sure the kitten is never alone and lonely. Interact with a kitten. Humans are superior to all living beings and have a special mission on our planet, i.e., to take care of the environment.</p>
        </div>
      </div>
     </div>
    </>
  );
};

export default Biology;

