import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { t } from "i18next";
import './Carousel.css'
import { useTranslation } from "react-i18next"; 
import { hero, playCircle, rightUp, videoHero } from "../../../assets/index";
 
  const Carousel = () => {

  return (
    <div >
<video
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height:"auto",
            zIndex: -1,
            paddingTop:'79px'
          }}
        >
          <source src={videoHero} type="video/mp4" />
          {/* You can add more <source> tags if you have other video formats like webm */}
          Your browser does not support the video tag.
        </video>
    </div>
  );
};

export default Carousel;
