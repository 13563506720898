import React from 'react';
import { about, aboutSosial, biology, gprLife, gprLifeBody, mission, service1 } from '../../../assets/index';

const CareerLife = () => {
  return (
    <>
     <div className='pt-[79px]'>
      <div
                        className="relative h-[203px] md:h-[339px]"
                        style={{
                          backgroundImage: `url(${gprLife})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                        }}
                      >
                        {/* Metin kutusu */}
                        <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-center">
                          <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold ">
                          GPR-da iş və həyat
                          </div>
                        </div>
                      </div>


                      <div className="max-w-containerSm md:max-w-container mx-auto md:mt-[40px] mt-[32px]">
                      <p className='text-[#FBFBFB] font-bold text-[24px] md:text-[40px]'>Uğur fərdlərin deyil, komandanın əsəridir</p>
     <div className='md:flex justify-between md:mt-[32px] mt-[20px]'>
        <div className='max-w-[687px]'>
        <p className='text-[#FBFBFB] '>GPR-ın hər bir böyük uğurun arxasında birlikdə işləməyi bacaran bir komanda dayanır. Fərqli sahələr üzrə mütəxəssislər bir araya gələrək, fərqli bacarıqlar, təcrübələr və baxış bucaqları ilə daha böyük məqsədlərə çatırlar, birlikdə çalışaraq daha böyük uğurlar əldə edirlər. <br></br><br></br>

Birlikdə işləməyin əsas şərtlərindən biri qarşılıqlı güvəndir, güclü ünsiyyət isə bu güvəni möhkəmləndirir, problemləri daha tez həll etməyə kömək edir, hədəflərə çatmaq üçün əməkdaşları doğru istiqamətləndirir, fikir mübadiləsini artırır və yeniliklər üçün zəmin yaradır. <br></br><br></br>

GPR sağlam həyat tərzini dəstəkləyir! <br></br><br></br>

Gəlin, birlikdə böyük işlər görmək üçün güclərimizi birləşdirək və yeni zirvələrə yüksələk!</p>

        </div>
        <div className='max-w-[513px] mt-4 md:mt-0'>
  <img src={gprLifeBody}></img>
</div>
</div>
      </div>
     </div>
    </>
  );
};

export default CareerLife;

