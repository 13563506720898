import React from 'react';
import { about, aboutSosial, biology, listIcon, mission, service1, service6, services6 } from '../../../assets/index';

const Print = () => {
  return (
    <>
     <div className='pt-[79px]'>
<div
      className="relative h-[203px] md:h-[339px]"
      style={{
        backgroundImage: `url(${services6})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* Metin kutusu */}
      <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-end">
        <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold relative bottom-10">
        Çap və poliqrafiya
        </div>
      </div>
    </div>


       <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px] text-[18px] ">
                                 <div>
                                 <p className='text-[#FBFBFB] font-medium'>Çap və poliqrafiya xidmətimiz müxtəlif növ çap məhsullarının hazırlanmasını və dizaynını əhatə edir.</p>
                                 <div className='flex items-center mt-8'>
                                   <img className='h-8 w-8' src={service6}></img>
                                   <p className='text-[#FBFBFB] ml-3'>Çap xidmətinə daxildir:</p>
                                 </div>
                                 <ul className='text-[#FBFBFB] mt-[28px] text-[18px] md:w-[1220px]'>
                                   <div className='flex'>
                                     <div className='flex mt-2 mr-2'>
                                     <img className='w-3 h-3' src={listIcon}></img>
                                     </div>
                                     <p className='flex-1'>Vizitkartlar;</p>
                                   </div>
                                   <div className='flex'>
                                     <div className='flex mt-2 mr-2'>
                                     <img className='w-3 h-3' src={listIcon}></img>
                                     </div>
                                     <p className='flex-1'>Flayer və broşürlər;</p>
                                   </div>
                                   <div className='flex'>
                                     <div className='flex mt-2 mr-2'>
                                     <img className='w-3 h-3' src={listIcon}></img>
                                     </div>
                                     <p className='flex-1'>Plakat və afişalar;</p>
                                   </div>
                                   <div className='flex'>
                                     <div className='flex mt-2 mr-2'>
                                     <img className='w-3 h-3' src={listIcon}></img>
                                     </div>
                                     <p className='flex-1'>Kitablar və kataloqlar;</p>
                                   </div>
                                   <div className='flex'>
                                     <div className='flex mt-2 mr-2'>
                                     <img className='w-3 h-3' src={listIcon}></img>
                                     </div>
                                     <p className='flex-1'>Stolüstü və divar təqvimləri;</p>
                                   </div>
                                   <div className='flex'>
                                     <div className='flex mt-2 mr-2'>
                                     <img className='w-3 h-3' src={listIcon}></img>
                                     </div>
                                     <p className='flex-1'>Stiker və etiketlər;</p>
                                   </div>
                                   <div className='flex'>
                                     <div className='flex mt-2 mr-2'>
                                     <img className='w-3 h-3' src={listIcon}></img>
                                     </div>
                                     <p className='flex-1'>Banner və vinillər.</p>
                                   </div>
                         
                         </ul>
                         <div className='flex mt-2 mt-8'>
                                   <img className='h-8 w-8' src={service6}></img>
                                   <p className='text-[#FBFBFB] ml-3'>Poliqrafiya xidmətimizə daxildir:</p>
                                 </div>
                                 <ul className='text-[#FBFBFB] mt-[28px] text-[18px] md:w-[1220px]'>
                                   <div className='flex'>
                                     <div className='flex mt-2 mr-2'>
                                     <img className='w-3 h-3' src={listIcon}></img>
                                     </div>
                                     <p className='flex-1'>Dizayn – loqotip, layout və qrafik dizayn;</p>
                                   </div>
                                   <div className='flex'>
                                     <div className='flex mt-2 mr-2'>
                                     <img className='w-3 h-3' src={listIcon}></img>
                                     </div>
                                     <p className='flex-1'>Kağız seçimi – qalınlıq, tekstura və rəng;</p>
                                   </div>
                                   <div className='flex'>
                                     <div className='flex mt-2 mr-2'>
                                     <img className='w-3 h-3' src={listIcon}></img>
                                     </div>
                                     <p className='flex-1'>Laminasiya – parlaq və ya mat effekt;</p>
                                   </div>
                                   <div className='flex'>
                                     <div className='flex mt-2 mr-2'>
                                     <img className='w-3 h-3' src={listIcon}></img>
                                     </div>
                                     <p className='flex-1'>UV laklama – xüsusi sahələrin parlaqlaşdırılması;</p>
                                   </div>
                                   <div className='flex'>
                                     <div className='flex mt-2 mr-2'>
                                     <img className='w-3 h-3' src={listIcon}></img>
                                     </div>
                                     <p className='flex-1'>Qablaşdırma – qutular, çantalar və digər məhsul qablaşdırmaları.</p>
                                   </div>
                         
                         </ul>
                         
                                 </div>
                               </div>
     </div>
    </>
  );
};

export default Print;

