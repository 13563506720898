import React from 'react';
import { about, aboutSosial, biology, listIcon, mission, service1, service7, service8, services8 } from '../../../assets/index';

const Network = () => {
  return (
    <>
     <div className='pt-[79px]'>
      <div
            className="relative h-[203px] md:h-[339px]"
            style={{
              backgroundImage: `url(${services8})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            {/* Metin kutusu */}
            <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-end">
              <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold relative bottom-10">
              Netvörkinq
              </div>
            </div>
          </div>


      <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px] text-[18px] ">
                                            <div>
                                            <p className='text-[#FBFBFB] font-medium'>Bu xidmətimiz vasitəsilə şirkətinizin işgüzar əlaqələrini genişləndiririk, peşəkar şəbəkələrdə şirkət və ya brendinizin təmsil olunmasına şərait yaradırıq, müvafiq biznes platformaları ilə şirkətiniz arasında əlaqələrin qurulmasına dəstək veririk.</p>
                                            <div className='flex items-center mt-8'>
                                              <img className='h-8 w-8' src={service8}></img>
                                              <p className='text-[#FBFBFB] ml-3'>Netvörkinq xidmətinə daxildir:</p>
                                            </div>
                                            <ul className='text-[#FBFBFB] mt-[28px] text-[18px] md:w-[1220px]'>
                                              <div className='flex'>
                                                <div className='flex mt-2 mr-2'>
                                                <img className='w-3 h-3' src={listIcon}></img>
                                                </div>
                                                <p className='flex-1'>Peşəkar netvörkinq platformaları ilə işgüzar əlaqələrin qurulması;</p>
                                              </div>
                                              <div className='flex'>
                                                <div className='flex mt-2 mr-2'>
                                                <img className='w-3 h-3' src={listIcon}></img>
                                                </div>
                                                <p className='flex-1'>Seminarlar, işgüzar görüşlər, konfranslar, biznes şəbəkələr və s.-də birbaşa ünsiyyət qurmaq imkanı;</p>
                                              </div>
                                              <div className='flex'>
                                                <div className='flex mt-2 mr-2'>
                                                <img className='w-3 h-3' src={listIcon}></img>
                                                </div>
                                                <p className='flex-1'>Böhran kommunikasiyası (böhran zamanı mesajların idarə olunması, mənfi xəbərlərin minimallaşdırılması, ictimai etibarın bərpası üsulları və s.);</p>
                                              </div>
                                              <div className='flex'>
                                                <div className='flex mt-2 mr-2'>
                                                <img className='w-3 h-3' src={listIcon}></img>
                                                </div>
                                                <p className='flex-1'>Biznes şəbəkələşmə tədbirlərində bizneslər, sahibkarlar və təşkilatlar ilə əməkdaşlıq və ünsiyyət;</p>
                                              </div>
                                              <div className='flex'>
                                                <div className='flex mt-2 mr-2'>
                                                <img className='w-3 h-3' src={listIcon}></img>
                                                </div>
                                                <p className='flex-1'>Sosial, ictimai, idman, xeyriyyə və s. prioritet sahələr üzrə tədbirlədə iştirak, şirkət və brendinizin tanıdılması.</p>
                                              </div>
     
                                    
                                    </ul>
                                    
                                            </div>
                                          </div>
     </div>
    </>
  );
};

export default Network;

