import React from 'react';
import Carousel from '../../Components/home/Carousel/Carousel';
import About from '../../Components/home/About/About';
import Contact from '../../Components/home/Contact/Contact';
import Awards from '../../Components/home/Awards/Awards';
import ServicesShort from '../../Components/home/ServicesShort/ServicesShort';
import ContactUs from '../../Components/home/ContactUs/ContactUs';
import News from '../../Components/home/News/News';



const Home = () => {
  return (
    <>
    {/* <Carousel/>
    <About/> 
    <ServicesShort/>
    <ContactUs/>
    <News/>
    <Awards/>
    <Contact/> */} 
    <div className='flex items-center justify-center h-screen'>
      <img src='./homepage.png'></img>
    </div>

    </>
  )
}

export default Home;