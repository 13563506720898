import React from 'react';
import { about, aboutSosial, biology, mission, recycling, service1 } from '../../../assets/index';

const NewsDetail = () => {
  return (
    <>
     <div className='pt-[79px]'>
     <div
  className="relative h-[203px] md:h-[339px]"
  style={{
    backgroundImage: `url(${aboutSosial})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }}
>
  {/* Metin kutusu */}
  <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-center">
    <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold">
    Xəbər haqqında
    </div>
  </div>
</div>


      <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px] ">
        <div className='md:w-[929px]'>
        <p className='text-[#FBFBFB] font-medium text-[18px] md:text-[24px]'>GPR takes care of environmental protection and addresses environmental concerns carefully and with full responsibility.</p>
        <p className='text-[#FBFBFB] mt-[28px] text-[16px] md:text-[20px]'>All waste from the offices of the company is collected according to the general principles of the “waste hierarchy”. Then we hand the collected waste over to specialist companies for further disposal. We minimize the use of products and technologies that harm the environment.</p>
        <p className='text-[#FBFBFB] mt-[40px] font-medium text-[18px] md:text-[24px]'>We take the following measures for waste minimization in our offices:</p>
        <ul className='text-[#FBFBFB] mt-[28px] text-[16px] md:text-[20px] list-disc'>
  <li>We do not use plastic ware in our offices and at the events we conduct.</li>
  <li>We hand in empty cans and bottles for reverse sale.</li>
  <li>We put edible by-products in separate containers and send them to our partners for further processing into fertilizers.</li>
  <li>We use seasonal fruits and vegetables to turn this waste into biofuel and fertilizers at events held in the offices for maintaining team spirit.</li>
  <li>A redundancy notice about the use of paper and toner is displayed while printing documents.</li>
  <li>Default printers use dual-sided white-and-black print for toner reduction and paper consumption.</li>
  <li>We use stationery and office supplies made from ecologically clean, biodegradable, or recycled materials.</li>
  <li>We apply electronic SIM cards to operators to reduce plastic material usage.</li>
  <li>We try to minimize construction and repair waste and reduce and recycle materials.</li>
</ul>

        </div>
      </div>
     </div>
    </>
  );
};

export default NewsDetail;

