import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../Components/Header/Header";
import { hero, service2 } from "../../assets";

export default function Career() {

  const [activeButton, setActiveButton] = useState("Startup");
  const buttons = [
    { key: "Startup", label: "Startup" },
    { key: "Veb-sayt hazırlanması", label: "Veb-sayt hazırlanması" },
    { key: "Mobil Tətbiqlərin Hazırlanması", label: "Mobil Tətbiqlərin Hazırlanması" },
    { key: "Təlimçi Bazası", label: "Təlimçi Bazası" },
    { key: "SMM Xidmətlər", label: "SMM Xidmətlər" },
  ];


  const { t, i18n } = useTranslation();
  return ( 
    <>
    <Header/>
       <div className="max-w-containerSm md:max-w-container mx-auto pt-[124px]">
    <p className="text-black text-[22px] md:text-[30px] font-bold tracking-wide mb-3">Layihən var? Bizimlə əlaqə saxla</p>
    <p className="text-lg">Xidmətlər</p>
    <div className="md:flex gap-3 mt-3 md:w-[800px] text-[14px] mb-[60px]">
      {buttons.map((button) => (
        <button
          key={button.key}
          onClick={() => setActiveButton(button.key)}
          className={`h-[36px] rounded-[20px] px-2 mt-2 md:mt-0 transition-all duration-300 ease-in-out ${
            activeButton === button.key
              ? "text-[white] bg-[#0023A1]"
              : "text-[black] bg-[#EBEEF2] hover:bg-[#0023A1] hover:text-[white]"
          }`}
        >
          {button.label}
        </button>
      ))}
    </div>
   <div className="flex justify-between">
    <div className="w-[643px]">
 <p className="text-black text-base font-normal mb-2">Ad, soyad*</p>
 <input className="bg-[#f4f4f4] rounded-xl h-10 w-full mb-7 p-2" placeholder="Lorem ipsum"></input>
 <p className="text-black text-base font-normal mb-2">E-mail*</p>
 <input className="bg-[#f4f4f4] rounded-xl h-10 w-full mb-7 p-2" placeholder="email@gmail.com"></input>
 <p className="text-black text-base font-normal mb-2">Əlaqə nömrəsi*</p>
 <input className="bg-[#f4f4f4] rounded-xl h-10 w-full mb-7 p-2"></input>
 <p className="text-black text-base font-normal mb-2">Mesaj</p>
 <textarea className="bg-[#f4f4f4] rounded-xl h-[114px] w-full mb-7 p-2" placeholder="Bizə yazın..."></textarea>
 <button className="text-base font-normal w-[132px] h-[42px] rounded-xl border bg-[#2C5AFF] text-[white] hover:bg-[#898989]">GÖNDƏR</button>
    </div>
    <div className="w-[267px]">
      <p className="text-black text-lg font-semibold mb-4">Əlaqə</p>
      <p className="ext-black text-base font-normal mb-4">E-mail: techway@gmail.com</p>
      <div className="flex">
      <p className="text-black text-base font-normal mb-8">Əlaqə nömrəsi: </p>
      <div>
      <p>+994 55 000 00 00</p>
      </div>
      </div>
      <p className="text-black text-lg font-semibold mb-4">Bizi izləyin</p>
      <div>
      <div className="flex gap-2">
      <a href="https://www.instagram.com/techway">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="black"/>
<path d="M8.50356 5.5H15.4964C17.1319 5.5 18.5 6.89691 18.5 8.65919V15.3408C18.5 17.1031 17.1319 18.5 15.4964 18.5H8.50356C6.86805 18.5 5.5 17.1031 5.5 15.3408V8.65919C5.5 6.89691 6.86805 5.5 8.50356 5.5ZM8.50356 5.79084C6.95697 5.79084 5.73595 7.09802 5.73595 8.65919V15.3408C5.73595 16.902 6.95697 18.2092 8.50356 18.2092H15.4964C17.043 18.2092 18.2641 16.902 18.2641 15.3408V8.65919C18.2641 7.09802 17.043 5.79084 15.4964 5.79084H8.50356Z" fill="black" stroke="black"/>
<path d="M14.759 11.9985C14.759 13.6201 13.5003 14.9022 12.0001 14.9022C10.4992 14.9022 9.24121 13.6202 9.24121 11.9985C9.24121 10.3768 10.4992 9.09485 12.0001 9.09485C13.501 9.09485 14.759 10.3768 14.759 11.9985ZM14.5231 11.9993C14.5231 10.5787 13.4121 9.38652 12.0001 9.38652C10.5881 9.38652 9.47716 10.5787 9.47716 11.9993C9.47716 13.4199 10.5881 14.6122 12.0001 14.6122C13.4121 14.6122 14.5231 13.4199 14.5231 11.9993Z" fill="black" stroke="black"/>
<path d="M15.9388 8.22847C15.9388 8.47882 15.7483 8.64555 15.5607 8.64555C15.3725 8.64555 15.1826 8.47912 15.1826 8.22847C15.1826 7.97783 15.3725 7.8114 15.5607 7.8114C15.7488 7.8114 15.9388 7.97783 15.9388 8.22847Z" fill="black" stroke="black"/>
</svg>
      </a>
     <a href="https://www.facebook.com/techway">
     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 0.5C18.2847 0.5 23.3923 5.54202 23.4983 11.8017V12.0004C23.4983 17.8081 19.1934 22.6103 13.6012 23.3895C13.078 23.4621 12.5426 23.5 11.9992 23.5C11.3716 23.5 10.7558 23.45 10.1567 23.3534L10.1566 23.3533C4.68178 22.4719 0.5 17.7236 0.5 12.0004C0.5 5.64907 5.64892 0.5 12 0.5Z" stroke="black"/>
<path d="M14.8024 8.00343C13.9772 8.00343 13.2946 8.61671 13.1858 9.41213L13.1698 9.42801V9.63603V12.2502V12.7502H13.6698H16.3256L15.959 15.2716H13.6698H13.1698V15.7716V23.4412C12.7849 23.48 12.3942 23.5 11.9992 23.5C11.5174 23.5 11.0427 23.4706 10.5771 23.4132V15.7716V15.2716H10.0771H7.59473V12.7502H10.0771H10.5771V12.2502V9.05166C10.5771 7.3778 11.9065 6.01395 13.5676 5.9596H13.6707C13.6867 5.9596 13.701 5.95887 13.713 5.95792H16.4044V8.00343H14.8024Z" fill="black" stroke="black"/>
</svg>

     </a>
      </div>

      </div>
      </div>
   </div>
     </div>
     
    </>
  );
}