import React from 'react';
import { about, aboutSosial, biology, ecosystem, mission, service1 } from '../../../assets/index';

const Ecosystem = () => {
  return (
    <>
     <div className='pt-[79px]'>
     <div
  className="relative h-[203px] md:h-[339px]"
  style={{
    backgroundImage: `url(${ecosystem})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }}
>
  {/* Metin kutusu */}
  <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-center">
    <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold">
    Ekosistemin qorunması
    </div>
  </div>
</div>


      <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px] ">
        <div>
        <p className='text-[#FBFBFB] font-medium text-[18px]'>Ekosistemlər – canlı və cansız komponentlərin qarşılıqlı təsir etdiyi mürəkkəb sistemlərdir. İnsan fəaliyyəti, urbanizasiya, sənayeləşmə və təbii resursların həddindən artıq istifadəsi ekosistemlərə ciddi zərər vurur. Ekosistemin qorunması isə həm ətraf mühitin sağlamlığı, həm də insan həyatının davamlılığı üçün vacibdir. <br></br> <br></br>

Ekosistemin qorunması sayəsində təmiz hava və təmiz su mövcud olur, meşələr karbon qazını udur, oksigen istehsal edir, qlobal istiləşmənin qarşısı alınır, canlıların müxtəlifliyi ekoloji tarazlığı tənzimləyir və s.  <br></br> <br></br>

Ekosistemin qorunması planetimizin sağlamlığı və gələcək nəsillərin rifahı üçün zəruridir. Hər bir fərd öz gündəlik həyatında kiçik addımlarla böyük dəyişikliyə töhfə verə bilər. Təbiətin qorunması ümumi məsuliyyətimizdir və bu məsuliyyət planetimizin gələcəyi üçün vacibdir. <br></br> <br></br>

<span className='text-gradient text-[20px]'>GPR hər kəsi ekosistemin mühafizəsinə çağırır!</span></p>
        </div>
      </div>
     </div>
    </>
  );
};

export default Ecosystem;

