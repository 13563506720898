

import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import { contact, contact1, contact2, contact3, contact4, contact5, contactBg, contactWhite1, contactWhite2, contactWhite3, contactWhite4, contactWhite5, gRight, whatsapp } from "../../assets/index";


const Contact = () => {

  const { t, i18n } = useTranslation(); 
  return (
    <> 
   <div className="pt-[79px]">
   <div className="max-w-containerSm md:max-w-container md:mt-[52px] mt-[20px] mx-auto">
     <div className='md:mb-[40px] mb-[20px] text-center'>
       <div className="flex justify-center items-center mx-auto">
  {/* Dekoratif Duvar */}
  <div className="w-[2px] h-[20px] bg-[#E0B497] mb-4 mt-4"></div>
  {/* Metin */}
  <p className="text-[16px] font-medium text-center text-[#E0B497] ml-[5px]">
  Əlaqə
  </p>
</div>

      <p className='text-[28px] md:text-[40px] font-bold text-center text-gradient'>Bizimlə Əlaqə Qura Bilərsiniz</p>
     </div>
   </div>
   <div className="grid grid-cols-2 md:grid-cols-5 max-w-containerSm md:max-w-container mx-auto gap-5">
  <div className="rounded-[16px] border-[#4E4E4E] border-[1px] bg-[#020202] px-[24px] py-[48px] box-border group transition-all duration-300 ease-in-out hover:bg-primeColor">
    <img className="h-10 w-10 group-hover:hidden" src={contact1}></img>
    <img className="h-10 w-10 hidden group-hover:block" src={contactWhite1}></img>
  <p className="text-[#C4C4C4] font-semibold mt-6 group-hover:text-[white]">Əlaqə nömrəsi</p>
  <p className="text-[white] font-semibold text-[18px]">+994 10 214 48 14</p>
  </div>
  <div className="rounded-[16px] border-[#4E4E4E] border-[1px] bg-[#020202] px-[24px] py-[48px] box-border group transition-all duration-300 ease-in-out hover:bg-primeColor">
  <img className="h-10 w-10 group-hover:hidden" src={contact2}></img>
  <img className="h-10 w-10 hidden group-hover:block" src={contactWhite2}></img>
  <p className="text-[#C4C4C4] font-semibold mt-6 group-hover:text-[white]">E-mail</p>
  <p className="text-[white] font-semibold text-[18px]">info@gpr.az</p>
  </div>
  <div className="rounded-[16px] border-[#4E4E4E] border-[1px] bg-[#020202] px-[24px] py-[48px] box-border group transition-all duration-300 ease-in-out hover:bg-primeColor">
  <img className="h-10 w-10 group-hover:hidden" src={contact3}></img>
  <img className="h-10 w-10 hidden group-hover:block" src={contactWhite3}></img>
  <p className="text-[#C4C4C4] font-semibold mt-6 group-hover:text-[white]">İnstagram</p>
  <p className="text-[white] font-semibold text-[18px]">@gpr.az</p>
  </div>
  <div className="rounded-[16px] border-[#4E4E4E] border-[1px] bg-[#020202] px-[24px] py-[48px] box-border group transition-all duration-300 ease-in-out hover:bg-primeColor">
  <img className="h-10 w-10 group-hover:hidden" src={contact4}></img>
  <img className="h-10 w-10 hidden group-hover:block" src={contactWhite4}></img>
  <p className="text-[#C4C4C4] font-semibold mt-6 group-hover:text-[white]">Facebook</p>
  <p className="text-[white] font-semibold text-[18px]">@gpr.az</p>
  </div>
  <div className="rounded-[16px] border-[#4E4E4E] border-[1px] bg-[#020202] px-[24px] py-[48px] box-border group transition-all duration-300 ease-in-out hover:bg-primeColor">
  <img className="h-10 w-10 group-hover:hidden" src={contact5}></img>
  <img className="h-10 w-10 hidden group-hover:block" src={contactWhite5}></img>
  <p className="text-[#C4C4C4] font-semibold mt-6 group-hover:text-[white]">LinkedIn</p>
  <p className="text-[white] font-semibold text-[18px]">@gpr.az</p>
  </div>
   </div>
      <div>
    <div className='mx-auto mt-10 md:mt-[100px] mb-[40px] md:mb-[160px] max-w-containerSm md:max-w-[1260px]'>
 <div className='md:flex mb-[40px] justify-between'>
<div className='md:w-[55%]'>
<form class="space-y-8">
      <div>
        <label for="company" class="block text-base font-medium text-[white]">Ad, soyad*</label>
        <input type="text" id="company" class="bg-[#292828] mt-2 block w-full pl-3 pr-3 h-10 border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm " ></input>
      </div>

    
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6"> 
    <div>
        <label for="phone" class="block text-base font-medium text-[white]">Əlaqə nömrəsi*</label>
        <input type="number" id="phone" class="bg-[#292828] mt-2 block w-full pl-3 pr-3 h-10 border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm " ></input>
      </div>
    <div>
        <label for="email" class="block text-base font-medium text-[white]">E-mail</label>
        <input type="email" id="email" class="bg-[#292828] mt-2 block w-full pl-3 pr-3 h-10 border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm " ></input>
      </div>
    </div>

    <div>
      <label for="message" class="block text-base font-medium text-[white]">Əlavə mesajınız</label>
      <textarea id="message" rows="4" class="bg-[#292828] h-36 mt-2 pl-3 pr-3 pt-3 block w-full border border-[#dddddd] rounded-xl shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm" ></textarea>
    </div>

    <div class="flex justify-start">
      <button type="submit" class="w-[148px] h-10 p-2.5 bg-primeColor rounded-xl justify-center items-center gap-2.5 inline-flex text-[white]">GÖNDƏR</button>
    </div>
  </form>
 </div>
 <div className='md:w-[37%] flex flex-col justify-between mt-4 md:mt-0'>
 <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d194473.18588939894!2d49.8549596!3d40.394592499999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d6bd6211cf9%3A0x343f6b5e7ae56c6b!2zQmFrxLE!5e0!3m2!1saz!2saz!4v1733142028974!5m2!1saz!2saz" width="100%" height="445" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div> 
 </div>
    </div>
  </div>
   </div>
    </>

  );
};

export default Contact;