import React from 'react';
import { about, aboutSosial, biology, listIcon, mission, recycling, service1 } from '../../../assets/index';

const Recycling = () => {
  return (
    <>
     <div className='pt-[79px]'>
     <div
  className="relative h-[203px] md:h-[339px]"
  style={{
    backgroundImage: `url(${recycling})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }}
>
  {/* Metin kutusu */}
  <div className="max-w-containerSm md:max-w-container mx-auto h-full flex items-center">
    <div className="text-white rounded text-[28px] md:text-[48px] font-bold md:font-extrabold">
    Təkrar emal
    </div>
  </div>
</div>


      <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px] ">
        <div>
        <p className='text-[#FBFBFB] font-medium md:w-[929px] text-[18px]'>Biomüxtəliflik – bitkilər, heyvanlar, mikroorqanizmlər və onların yaşadığı ekosistemlər arasında mövcud olan qarşılıqlı əlaqələrin zənginliyidir. Bu müxtəliflik, planetimizin dayanıqlığı və insanlığın rifahı üçün vacibdir. Təbiətin bu mühüm sərvətinin qorunması isə insanlığın əsas missiyalarından biridir.
        Ətraf mühitin mühafizəsi və biomüxtəlifliyin qorunması üçün GPR biomüxtəlifliyin əhəmiyyəti və onun qorunmasının vacibliyi və tullantıların idarə olunması istiqamətləri üzrə layihələr həyata keçirir və hər kəsi bu layihələrdə iştirak etməyə və dəstək olmağa çağırır.</p>
        <p className='text-[#FBFBFB] mt-[40px] font-semibold text-[20px] text-gradient'>Bizim Hər Gün Qoruya Biləcəyimiz Biomüxtəliflik</p>
        <ul className='text-[#FBFBFB] mt-[28px] text-[18px] md:w-[1220px]'>
          <div className='flex'>
            <div className='flex items-center mr-2'>
            <img className='w-3 h-3' src={listIcon}></img>
            </div>
            <p className='flex-1'>Tullantıların idarəsi: plastik istifadəsini azaltmaq, təkrar emal etmək və tullantıları düzgün idarə etmək.kollektivdə çalışmaq;</p>
          </div>
          <div className='flex'>
            <div className='flex items-center mr-2'>
            <img className='w-3 h-3' src={listIcon}></img>
            </div>
            <p className='flex-1'>Yaşıllıqların artırılması: ağac əkmək, bağçılıqla məşğul olmaq və şəhər mühitini yaşıllaşdırmaq;</p>
          </div>
          <div className='flex'>
            <div className='flex items-center mr-2'>
            <img className='w-3 h-3' src={listIcon}></img>
            </div>
            <p className='flex-1'>Məsuliyyətli istehlak: yerli və mövsümi məhsullara üstünlük vermək, davamlı istehsal üsullarını dəstəkləmək.</p>
          </div>

</ul>

        </div>
      </div>
     </div>
    </>
  );
};

export default Recycling;

