import React from 'react';
import { about, aboutSosial, biology, mission, organic, service1 } from '../../../assets/index';

const Organic = () => {
  return (
    <>
     <div className='pt-[79px]'>
     <div
  className="relative h-[203px] md:h-[339px]"
  style={{
    backgroundImage: `url(${organic})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }}
>
  {/* Metin kutusu */}
  <div className="max-w-container mx-auto h-full flex items-center">
    <div className="text-white p-4 rounded text-[28px] md:text-[48px] font-bold md:font-extrabold">
    Canlılar aləmi
    </div>
  </div>
</div>


      <div className="max-w-containerSm md:max-w-container mx-auto mt-[32px] md:mt-[40px]">
        <div className='md:w-[929px]'>
        <p className='text-[#FBFBFB] font-medium text-[18px]'><span className='text-[24px]'>Sevgi və məsuliyyət</span> <br></br > <br></br>Heyvanlar aləmi Yer kürəsinin ən qiymətli sərvətlərindən biridir. Hər bir heyvan növü təbiətin tarazlığında xüsusi rol oynayır və ekosistemin sağlamlığı üçün əvəzolunmazdır. Heyvanlar yalnız ekosistemin bir hissəsi deyil, həm də insanlarla sıx bağlıdır. Onların qorunması bir neçə əsas səbəbə görə həyati əhəmiyyətə malikdir:<br></br> <br></br>

        <span className='text-gradient text-[20px]'>Ekoloji tarazlıq:</span>  Hər bir heyvan növü ekoloji tarazlığın bir hissəsidir. Bir növün itməsi digərlərinin çoxalmasına və ya azalmasına səbəb ola bilər, bu isə ekoloji tarazlığı poza bilər. <br></br > <br></br>

        <span className='text-gradient text-[20px]'>Qida və resurs mənbəyi:</span> İnsanlar heyvanlardan qida, geyim, tibbi tədqiqatlar və digər ehtiyaclar üçün istifadə edir. <br></br > <br></br>

        <span className='text-gradient text-[20px]'>Təbiətin gözəlliyi:</span> Heyvanlar təbiətin zənginliyini və estetik dəyərini artırır, turizmin inkişafına təkan verir. <br></br > <br></br>

        <span className='text-gradient text-[20px]'>Gələcək nəsillər üçün məsuliyyət:</span> Heyvanların qorunması təkcə bizim deyil, gələcək nəsillərin də borcudur.  <br></br > <br></br>

Heyvanların qorunması sadəcə onların yox olmaması üçün deyil, həm də ekosistemin davamlılığı və insanların rifahı üçün vacibdir. Hər birimizin bu işdə rolu böyükdür – hətta kiçik addımlar belə təbiətin və heyvanların qorunmasına böyük təsir göstərə bilər.  <br></br > <br></br>
<span className='text-gradient text-[20px]'>Unutmayaq ki, heyvanları qorumaq, gələcəyimizi qorumaq deməkdir. Təbiəti və onun canlılarını qorumaq üçün hamılıqla çalışaq!</span></p>
        </div>
      </div>
     </div>
    </>
  );
};

export default Organic;

